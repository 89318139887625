<template>
  <div>
    <draggable
      class="l-drag-from"
      :list="_items"
      group="form"
      :options="{ animation: 300, chosenClass: 'choose', sort: true }"
      @start="
        drag = true;
        selectFormItem = null;
      "
      @end="drag = false"
    >
      <div
        v-for="(cp, id) in _items"
        :key="id"
        class="l-form-item"
        @click.stop="selectItem(cp)"
        :style="getSelectedClass(cp)"
      >
        <div class="l-form-header">
          <p>{{ cp.title }}</p>
          <div class="l-option">
            <i class="el-icon-close" @click="delItem(id)"></i>
          </div>
          <form-design-render :config="cp" />
        </div>
      </div>
    </draggable>
    <div style="color: #c0bebe; text-align: center; width: 90%; padding: 5px">
      ☝ 拖拽控件到布局容器内部
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import FormDesignRender from "@/views/admin/layout/form/FormDesignRender";

export default {
  name: "SpanLayout",
  components: { draggable, FormDesignRender },
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    _items: {
      get() {
        return this.items;
      },
      set(val) {
        this.items = val;
      },
    },

    selectFormItem: {
      get() {
        return this.$store.state.selectFormItem;
      },
      set(val) {
        this.$store.state.selectFormItem = val;
      },
    },
  },
  data() {
    return {
      drag: false,
    };
  },

  methods: {
    selectItem(cp) {
      this.selectFormItem = cp;
    },
    getSelectedClass(cp) {
      return this.selectFormItem && this.selectFormItem.id === cp.id
        ? "border-left: 4px solid #f56c6c"
        : "";
    },
    delItem(index) {
      this.$confirm(
        "删除组件将会连带删除包含该组件的条件以及相关设置，是否继续?",
        "提示",
        {
          confirmButtonText: "确 定",
          cancelButtonText: "取 消",
          type: "warning",
        }
      ).then(() => {
        console.log(this._items, index);
        this._items.splice(index, 1);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.choose {
  border: 1px dashed @theme-primary !important;
}

.l-drag-from {
  min-height: 50px;
  background-color: rgb(245, 246, 246);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 30px;
  .l-form-item {
    cursor: grab;
    background: #ffffff;
    padding: 10px;
    border: 1px solid #ebecee;
    margin: 5px 0;
    width: 45%;
  }
}

.l-form-header {
  font-size: small;
  color: #818181;
  text-align: left;
  position: relative;
  background-color: #fff;

  p {
    position: relative;
    margin: 0 0 10px 0;

    span {
      position: absolute;
      left: -8px;
      top: 3px;
      color: rgb(217, 0, 19);
    }
  }

  .l-option {
    position: absolute;
    top: -10px;
    right: -10px;

    i {
      font-size: large;
      cursor: pointer;
      color: #8c8c8c;
      padding: 5px;

      &:hover {
        color: @theme-primary;
      }
    }
  }
}
</style>
